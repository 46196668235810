<template>
  <div>
    <Header>转出到余额</Header>
    <div class="content">
      <div class="title">转出到余额</div>
      <div class="input">
        <span>¥</span>
        <input
          type="text"
          placeholder="请输入转出余额"
          v-model="num"
          @input="inputMony($event)"
        />
      </div>
      <div class="info">
        <div class="left">*单笔转出金额必须大于或等于100元</div>
        <div class="right" @click="num = walletInfo.balance">全部转出</div>
      </div>
      <div class="btn" @click="submit" :class="num ? 'active' : null">
        确认转出
      </div>
    </div>
    <!-- 弹框 -->
    <div class="tips" v-if="showTip">
      <div class="box">
        <div class="title">提示</div>
        <div class="info">{{ tip }}</div>
        <div class="btn" @click="showTip = false">我知道了</div>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="tips" v-if="false">
      <div class="box">
        <div class="title">提示</div>
        <div class="info">转出到余额成功</div>
        <div class="btn">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      walletInfo: {},
      num: '',
      showTip: false,
      tip: '单笔转出金额不得小于100元',
    };
  },
  mounted() {
    this.getRewardWallet();
  },
  methods: {
    inputMony(e) {
      const res = e.target.value.match(/^\d{0,8}(\.?\d{0,2})/g)[0] || '';
      this.$nextTick(() => {
        this.num = res;
      });
    },
    async getRewardWallet() {
      const res = await this.$api.rewardWallet({
        token: localStorage.getItem('token'),
      });
      if (res.code === 0) {
        this.walletInfo = res.data || {};
      }
    },
    async submit() {
      if (parseFloat(this.num) > this.walletInfo.balance) {
        this.tip = '可转金额不足';
        this.showTip = true;
        return;
      }
      if (parseFloat(this.num) < 100) {
        this.tip = '单笔转出金额不得小于100元';
        this.showTip = true;
        return;
      }
      const res = await this.$api.rewardToUserMoney({
        fee: this.num,
        token: localStorage.getItem('token'),
      });
      if (res.code === 0) {
        this.showTip = true;
        this.tip = '转出到余额成功';
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 20px;
  .title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #010000;
    margin-bottom: 20px;
  }
  .input {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f4f5;
    span {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #010000;
      margin-right: 10px;
    }
    input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 23px;
      font-weight: normal;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .left {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #fa3d3d;
    }
    .right {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0754d3;
    }
  }
  .btn {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    margin: 100px auto 0;
    height: 42px;
    background: #afbed8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: #0754d3;
      color: #eee;
    }
  }
}
.tips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    padding: 20px;
    box-sizing: border-box;
    width: 282px;
    height: 170px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      color: #000;
    }
    .info {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 80%;
      height: 30px;
      background: #0754d3;
      border-radius: 3px;
      color: #fff;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }
}
</style>
